import React from 'react'
import { useIntl, Link } from 'gatsby-plugin-intl'
import Layout from '../../components/Layout/index'
import Header from '../../modules/Header'
import Footer from '../../modules/Footer'
import SEO from '../../components/seo'
import PressRelease from '../../components/PressRelease'
import ITBBookingAppointment from '../../components/PressRelease/ITBBookingAppointment'

import messages from '../../messages/pageTitles.lang'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import Image from '../../images/news/berge-meer_1_1_en.png'
import ImageDE from '../../images/news/berge-meer_1_1_de.png'

import { newsList } from '../../components/NewsPage/data'

/* eslint-disable max-len */
const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleNewsBergeMeerLaunch)}
        imageAlt={formatMessage(messages.TitleNewsBergeMeerLaunch)}
        description={formatMessage(metaDescription.NewsBergeMeerLaunchDescription)}
        image={locale === 'de' ? ImageDE : Image}
        lang={locale}
      />
      <PressRelease
        article={newsList.bergeMeer}
        articleLinks={{
          tsbo: (
            <Link to="/products/quickstart/">
              TravelSandbox®
            </Link>
          ),
          fvw: (
            <a href='https://www.fvw.de/touristik/technologie/mit-freshcells-technik-berge--meer-startet-neue-direktvertriebs-website-243651' target="_blank" rel="noopener noreferrer">https://www.fvw.de/touristik/technologie/mit-freshcells-technik-berge--meer-startet-neue-direktvertriebs-website-243651</a>
          )
        }}
        addon={<ITBBookingAppointment />}
      />
    </Layout>
  )
}

export default IndexPage
